import * as React from 'react';
import { GetServerSideProps, NextPage } from 'next';
import { QueryHookOptions, useQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { getServerSession } from 'next-auth/next';
import {
  setHubPage,
  setMilestonesPage,
  setNewsPage,
  setProposals,
  setTeamPage,
  setMaps,
} from 'Client/utils/reduxReducers/pages/pagesReducer';
import { HubPage as Page, HubPageProps } from 'Pages';
import {
  listGaudiStakeholders,
  getTeamsPageWithVersion,
  getOrderedPlanningAppPages,
} from 'Services/pages';
import { listNews } from 'Services/news';
import { getOrderedProposalCards } from 'Services/proposals';
import { RichServerSideContext } from 'Shared/types/middlewares';
import { seeProjectBeforeLaunchRedirect } from 'Server/services/user';
import { getOpenGraphInfoLocalised } from 'Server/services/settings';
import {
  GET_MAP_PAGES_QUERY,
  GET_MILESTONES_PAGES_QUERY,
  GET_PROPOSAL_PAGES_QUERY,
  GET_TEAM_PAGES_QUERY,
  GET_HUB_PAGES_QUERY,
  GET_PLANNING_APPS_PAGES_QUERY,
  GET_PLANNING_APPS_FROM_PROJECT_QUERY,
} from 'Client/pages/hub/HubPage.gql';
import { useHubContext } from 'Client/utils/hooks';
import { LOADER_ACTION_TYPES } from 'Client/context/loaderReducer';
import { removeArchived } from 'Client/pages/proposals/utils';
import { removePagesWithoutContent } from 'Client/pages/proposals/utils/filterProposalPages';
import { getConsents } from 'Services/consents';
import { CONSENT_TYPES } from 'Client/constants/consents';
import { ProjectGaudi } from 'Shared/types';
import getClient from 'Shared/utils/apollo-client';
import { getProjectDetails } from 'Shared/utils/getProjectDetails';
import generateApiToken from 'Server/utils/generateApiToken';
import { getNavBarItems } from 'Server/services/navbar/getNavBarItems';
import { authOptions } from 'pages/api/auth/[...nextauth]';
import { INextAuthUser } from './api/auth/types';

interface PageProps extends HubPageProps {
  project: ProjectGaudi;
  withPlanningApps?: boolean;
  lang: string;
}

export const HubPage: NextPage<PageProps> = ({
  lang,
  project,
  ...props
}: PageProps) => {
  props.navbarItems = props.navbarItems.sort((a, b) => a.order - b.order);
  const projectId = project?._id;
  const dispatch = useDispatch();
  const {
    dispatch: hubDispatch,
    state: { loading, from },
  } = useHubContext();

  const options: QueryHookOptions = {
    variables: { params: { projectId, lang } },
    context: {
      fetchOptions: {
        method: 'GET',
      },
    },
  };
  const { data: hubPageData } = useQuery(GET_HUB_PAGES_QUERY, options);
  const { data: proposalsData } = useQuery(GET_PROPOSAL_PAGES_QUERY, options);
  const { data: mapPagesData } = useQuery(GET_MAP_PAGES_QUERY, options);
  const { data: milestonesPagesData } = useQuery(
    GET_MILESTONES_PAGES_QUERY,
    options
  );
  const { data: teamPagesData } = useQuery(GET_TEAM_PAGES_QUERY, options);

  React.useEffect(() => {
    dispatch(setNewsPage({ content: { newsPosts: props.newsPosts } }));
  }, []);

  React.useEffect(() => {
    if (hubPageData && hubPageData.getHubPages && hubPageData.getHubPages[0]) {
      dispatch(setHubPage(hubPageData.getHubPages[0]));
    }
  }, [hubPageData]);

  React.useEffect(() => {
    if (proposalsData && proposalsData.getProposalPages) {
      const propPages = proposalsData.getProposalPages
        .filter(removeArchived)
        .filter(removePagesWithoutContent);
      // const ordered = orderProposals(propPages); // page.order is not applicable yet
      dispatch(setProposals(propPages));
    }
  }, [proposalsData]);

  React.useEffect(() => {
    if (mapPagesData && mapPagesData.getMapPages) {
      const pages = mapPagesData.getMapPages.filter(removePagesWithoutContent);
      dispatch(setMaps(pages));
    }
  }, [mapPagesData]);

  React.useEffect(() => {
    if (
      milestonesPagesData &&
      milestonesPagesData.getMilestonesPages &&
      milestonesPagesData.getMilestonesPages[0]
    ) {
      dispatch(setMilestonesPage(milestonesPagesData.getMilestonesPages[0]));
    }
  }, [milestonesPagesData]);

  React.useEffect(() => {
    if (
      teamPagesData &&
      teamPagesData.getTeamsPages &&
      teamPagesData.getTeamsPages[0] &&
      props.stakeholders
    ) {
      const teamPage = {
        ...teamPagesData.getTeamsPages[0],
        content: {
          ...teamPagesData.getTeamsPages[0].content,
          stakeholders: props.stakeholders,
        },
      };
      dispatch(setTeamPage(teamPage));
    }
  }, [teamPagesData, props.stakeholders]);

  if (project?.features?.glaPlanningApps) {
    if (loading && from !== 'hub') {
      /*
       * If loading is dispatched from the hub page
       * we only want to terminate loading state on the goal page
       */
      hubDispatch({
        type: LOADER_ACTION_TYPES.SET_LOADING,
        loading: false,
        from: 'hub',
      });
    }
  }
  return <Page {...props} role="main" />;
};

export const getServerSideProps: GetServerSideProps = async ({
  locale,
  req,
  res,
}: RichServerSideContext) => {
  const nextAuthSession = await getServerSession(req, res, authOptions);
  const user = nextAuthSession?.user as INextAuthUser;
  const projectName = req.headers.host.split('.')[0];
  const project = await getProjectDetails(projectName);
  const apiToken = await generateApiToken(project, user);

  if (!project) {
    return {
      props: {},
    };
  }

  const lang = locale || 'en-GB';
  const projectId = project?._id;
  let newsPosts = [];
  newsPosts = await listNews({ projectId, lang, limit: 3 });
  const orderedProposalCards = await getOrderedProposalCards({
    lang,
    projectId,
  });
  // used only for the section with the old proposal cards (about to be deprecated)
  // can be both a proposal page or a map page card
  const proposals = orderedProposalCards.filter(removeArchived).map((prop) => {
    const { content } = prop;
    return { ...prop, ...content };
  });
  const teamPage = await getTeamsPageWithVersion({ projectId, locale: lang });
  const stakeholders =
    teamPage?.content?.stakeholders ||
    (await listGaudiStakeholders({
      locale,
      projectName,
    }));

  // returns undefined if no redirect required
  const shouldRedirect = seeProjectBeforeLaunchRedirect({ user, project });
  const openGraphInfo = await getOpenGraphInfoLocalised(project, lang);

  const userHasConsent = async (): Promise<boolean> => {
    const consents = await getConsents({
      email: user.email,
      type: [CONSENT_TYPES.PROJECT_NEWS, CONSENT_TYPES.PROJECT_LAUNCH],
      project: projectName,
      enabled: 'true',
    });
    if (consents) return consents.length > 0;
    return false;
  };

  const userHasSubscribed =
    user && !project.features.hideProjectNewsSubscription
      ? await userHasConsent()
      : false;

  const ApolloClient = getClient(apiToken);
  const { data } = project?.features?.planningApps
    ? project?.features?.glaPlanningApps
      ? await ApolloClient.query({
          query: GET_PLANNING_APPS_FROM_PROJECT_QUERY,
          variables: {
            projectId,
            offset: 0,
            limit: 5,
          },
          context: {
            fetchOptions: {
              method: 'GET',
            },
          },
        })
      : await ApolloClient.query({
          query: GET_PLANNING_APPS_PAGES_QUERY,
          variables: { params: { projectId, lang } },
          context: {
            fetchOptions: {
              method: 'GET',
            },
          },
        })
    : { data: null };

  const hasNavbarV2 = project.features?.navbarV2;
  const nabvar = hasNavbarV2
    ? await getNavBarItems({
        projectId: project._id,
        language: lang,
      })
    : null;
  return {
    props: {
      proposals,
      navbarItems: (nabvar?.items || [])
        .filter((i) => !!i.active)
        .map((i, idx) => ({ ...i, order: idx })),
      withPlanningApps: !!project.features.planningApps,
      planningApps: project?.features?.planningApps
        ? await getOrderedPlanningAppPages({
            projectId,
            projectName,
            planningApps: project?.features?.glaPlanningApps
              ? {
                  isContentOnly: true,
                  planningApps: req.url?.includes('planAppFilters') // Remove inital load of planning apps when filters are present
                    ? []
                    : data?.getPlanningAppsFromProject,
                }
              : {
                  isContentOnly: false,
                  planningApps: data?.getPlanningAppPages,
                },
          })
        : null,
      newsPosts,
      stakeholders: stakeholders || [],
      lang,
      shouldShowContent: !shouldRedirect,
      openGraphInfo,
      userHasSubscribed,
      project,
    },
  };
};

export default HubPage;
